import { ofetch } from "ofetch";
import { useSessionStore } from "@/stores/session";
import { useGetDeviceType } from "@/composables/useUserAgent";
import useNeedLoginPopup from "@/composables/useNeedLoginPopup";
import { RESPONSE_STATUS, ERROR_CODES } from "@/constants";

async function request(path, payload, extraOpts = {}) {
  const nuxtApp = useNuxtApp();
  const { isUnAuthPopup } = useNeedLoginPopup();

  let clientHeaders = {};

  /**
   * all authenticated requests must be done on the client side
   * otherwise the token will not be appended to the header
   */
  if (process.client) {
    const sessionStore = useSessionStore();
    clientHeaders = { "api-authsign": 0, "api-timestamp": Date.now() };
    if (sessionStore.isAuthenticated) {
      const token = sessionStore.token;
      Object.assign(clientHeaders, { "Common-Token": token });
    }

    if (
      Object.values(sessionStore.identifiers).length > 0 &&
      sessionStore.identifiers.serialNumber
    ) {
      Object.assign(clientHeaders, { ...sessionStore.identifierHeaders });
    }
  }

  const deviceType = useGetDeviceType();

  let options = {
    baseURL: process.env.BASE_URL,
    headers: {
      Accept: "application/json",
      "App-Locale": nuxtApp.$i18n.locale.value || "ar",
      "Device-type": deviceType || "PC",
      ...clientHeaders,
      ...(extraOpts.headers || {}),
    },
    method: extraOpts.method,
  };

  if (["POST", "PATCH", "PUT"].includes(options.method)) {
    options = {
      ...options,
      params: extraOpts.params || {},
      body: payload,
      query: extraOpts.query || {},
    };
  }

  if (options.method == "GET") {
    options = { ...options, query: payload || {} };
  }

  return await ofetch(path, {
    ...options,
    onRequest: (context) => {
      if (process.server) {
        const requestLog = {
          baseURL: context.options.baseURL,
          request: context.request,
          method: context.options.method,
          headers: JSON.stringify(context.options.headers),
          query: JSON.stringify({ ...context.options.query }),
        };
        // console.log("request", requestLog);
      }

      return context;
    },
    onResponse: (context) => {
      if (process.server) {
        const serverLog = {
          ...context.response._data,
          response: JSON.stringify(context.response._data?.response),
        };
        // console.log("response", serverLog);
      }
      if (context.response._data.status !== 200) {
        const stack = {
          ...context.response._data,
          response: JSON.stringify(context.response._data?.response),
        };

        if (context.response._data.status === ERROR_CODES.CONTENT_NOT_FOUND) {
          throw createError({
            statusCode: 404,
          });
        }
      }

      return context;
    },
  });
}

export default function () {
  return {
    get: (path, payload, extraOpts) => {
      return request(path, payload, { ...extraOpts, method: "GET" });
    },
    post: (path, payload, extraOpts) =>
      request(path, payload, { ...extraOpts, method: "POST" }),
    patch: (path, payload, extraOpts) =>
      request(path, payload, { ...extraOpts, method: "PATCH" }),
    put: (path, payload, extraOpts) =>
      request(path, payload, { ...extraOpts, method: "PUT" }),
  };
}
